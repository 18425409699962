import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => {

  return (
    <Layout>
      <SEO />

      <div className="container">
        <div className="columns is-gapless is-centered">
          <div className="has-text-centered is-margin-top-12 is-margin-bottom-16">

            <h2 className="is-size-1 is-family-primary has-text-weight-bold">
              Obrigado
            </h2>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
